html {
	background: #2b2b40;
}

html, body {
	margin: 0;
	padding: 0;
}

body {
	font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

a {
	color: #00a4ca;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

img {
	width: 100%;
}

h1 strong, h2 strong {
	font-weight: 700;
}

h1 {
	font-weight: 300;
	font-size: 2.0em;
	margin: 0;
}

h2 {
	font-weight: 300;
	font-size: 1.6em;
	margin: 0 0 30px 0;
}

h3 {
	margin: 20px 0 10px 0;
}


p, address {
	color: #666;
	margin-bottom: 20px;
	font-weight: 300;
	line-height: 1.4em;
}

pre {
    white-space:pre-line;
}
